/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PhotoRequestStatus {
    Draft = 'DRAFT',
    Created = 'CREATED',
    OnPhotoShoot = 'ON_PHOTO_SHOOT',
    Photographed = 'PHOTOGRAPHED',
    OnRetoushing = 'ON_RETOUSHING',
    Done = 'DONE',
    Unspecified = 'UNSPECIFIED',
    ReadyToRetouch = 'READY_TO_RETOUCH',
    PhotoLoading = 'PHOTO_LOADING',
    PhotoLoadingError = 'PHOTO_LOADING_ERROR',
    Retouched = 'RETOUCHED',
    InReview = 'IN_REVIEW',
    Reviewed = 'REVIEWED',
    OnPhotoShootPanoramic = 'ON_PHOTO_SHOOT_PANORAMIC',
    InReviewBeforeRetouch = 'IN_REVIEW_BEFORE_RETOUCH'
}

export function PhotoRequestStatusFromJSON(json: any): PhotoRequestStatus {
    return PhotoRequestStatusFromJSONTyped(json, false);
}

export function PhotoRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestStatus {
    return json as PhotoRequestStatus;
}

export function PhotoRequestStatusToJSON(value?: PhotoRequestStatus | null): any {
    return value as any;
}

